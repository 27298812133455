import { convertSkToWgs84 } from '@tmap-web-lib/utils'
import gte from 'semver/functions/gte'
import lt from 'semver/functions/lt'
import { BaseInterfaceImpl } from './BaseInterfaceImpl'
import { TmapAppInterfaceParams } from './internal-types'
import { TmapApp } from './TmapApp'
import { TmapAppInterface } from './TmapAppInterface'
import {
  CIAuthResult,
  DrivingTraceData,
  FavoriteToggleResult,
  FavoriteYesNoResult,
  LoginMethodType,
  PaymentActivityResult,
  PickContactResult,
  PoiDataJson,
  PointActivityResult,
  QRCodeScanResult,
  RecentDestinationData,
  SearchResult,
  SubwayRouteData,
  SubwayRouteDetailsResult,
  SubwayRouteResult,
  TIDAuthResult,
  TmapMapContext,
  TransportFavoriteToggleResult,
  TransportFavoriteYesNoResult,
  Wgs84Coordinate,
} from './types'
import { addNativeCallback, applyUrlEncodedProperties, safetyAsyncCall, transformTipOffList } from './utils'

/**
 * callbackId 가 지정된 것들에 대한 설명.
 * 다른 웹뷰를 여는 api는 사용자가 웹뷰를 닫아야 callbackJS 호출하기 때문에 timeout 처리 불가능.
 * 동기적으로 여러번 호출할수 있는 구조가 아니라서 callbackId 고정.
 */
export class TmapIosInterfaceImpl extends BaseInterfaceImpl implements TmapAppInterface {

  constructor(tmapApp: TmapApp) {
    super(tmapApp)
  }

  makeToast({ msg }: TmapAppInterfaceParams['makeToast']) {
    this.callCustomUrl('makeToast', { msg })
  }

  makeDialogPopup({
    msg, title = '', cancel = '취소', confirm = '확인',
  }: TmapAppInterfaceParams['makeDialogPopup']) {
    return new Promise<boolean>(resolve => {
      // iOS는 cancel버튼 눌렀을때 콜백안함.
      // - 10.4.0 부터 cancel 정상 동작.
      // callback listener가 계속 쌓이지 않도록 동일한 callbackId 사용.
      const callJS = addNativeCallback((result: number) => resolve(result === 1), 'makeDialogPopup')
      if (!this.env.isInApp) {
        if (cancel) {
          resolve(window.confirm(msg))
        } else {
          window.alert(msg)
          resolve(true)
        }
      }

      this.callCustomUrl('makeDialogPopup', {
        title,
        msg,
        cancel,
        confirm,
        callJS,
      })
    })
  }

  onBackKeyPressed({ errorCode, errorMsg, isAnimated }: TmapAppInterfaceParams['onBackKeyPressed'] = {}) {
    this.callCustomUrl('onBackKeyPressed', { errorCode, errorMsg, isAnimated })
  }

  async selectImage() {
    return null
  }

  openBrowser({ url }: TmapAppInterfaceParams['openBrowser']) {
    this.callCustomUrl('openBrowser', { url })
  }

  phoneCall({ strTel }: TmapAppInterfaceParams['phoneCall']) {
    this.callCustomUrl('phoneCall', { telNumber: encodeURIComponent(strTel) })
  }

  getTipOffList({ $timeout = 300 }: TmapAppInterfaceParams['getTipOffList'] = {}) {
    return safetyAsyncCall<{ result: DrivingTraceData[] }>(callbackJS => {
      this.callCustomUrl('getTipOffList', { callbackJS })
    }, {
      resolver: (resolve, rawData) => {
        try {
          const data = transformTipOffList(rawData)
          resolve(data)
        } catch {
          resolve({ result: [] })
        }
      },
      defaultValue: { result: [] },
      timeout: this.selectTimeout('8.1.0', $timeout),
    })
  }

  deleteTipOff({ id }: TmapAppInterfaceParams['deleteTipOff']) {
    this.callCustomUrl('deleteTipOff', { id })
  }

  async search({ keyword, reqType }: TmapAppInterfaceParams['search']) {
    if (lt(this.env.appVersion, '10.7.0')) return null
    return safetyAsyncCall<SearchResult | null>(callbackJS => {
      this.callCustomUrl('search', { jsonString: JSON.stringify({ keyword, reqType, callbackJS }) })
    }, {
      resolver: (resolve, ...[poiId, navSeq, poiName, inAddr, inAddrType, navX, navY, centerX, centerY]) => {
        const nav = convertSkToWgs84({ x: navX, y: navY })
        const center = convertSkToWgs84({ x: centerX, y: centerY })

        if (poiId == null) {
          resolve(null)
        } else {
          resolve({
            poiId: Number(poiId),
            navSeq: Number(navSeq),
            poiName,
            inAddr,
            inAddrType,
            navLon: nav.longitude,
            navLat: nav.latitude,
            centerLon: center.longitude,
            centerLat: center.latitude,
          })
        }
      },
      callbackId: 'searchCallback',
      timeout: null,
    })
  }

  searchSubway({ keyword, cityCode, cityName }: TmapAppInterfaceParams['searchSubway']) {
    return safetyAsyncCall<SubwayRouteResult>(callbackJS => {
      this.callCustomUrl('searchSubway', { keyword, cityCode, cityName, callbackJS })
    }, {
      resolver: (resolve, ...result) => {
        resolve({
          result: result[0] === 'true',
          data: result[1] ? JSON.parse(result[1]) : [],
        })
      },
      callbackId: 'searchSubwayCallback',
      defaultValue: { result: false, data: [] },
      timeout: this.selectTimeout('10.6.0', null, 0),
    })
  }

  getRecentDestination({ count, $timeout = 300 }: TmapAppInterfaceParams['getRecentDestination']) {
    return safetyAsyncCall<{ result: RecentDestinationData[] }>(callbackJS => {
      this.callCustomUrl('getRecentDestination', { count, callbackJS })
    }, {
      resolver: (resolve, rowData) => {
        try {
          const data = JSON.parse(rowData)
          resolve(data)
        } catch {
          resolve({ result: [] })
        }
      },
      defaultValue: { result: [] },
      timeout: this.selectTimeout('8.1.0', $timeout),
    })
  }

  registPoi({ type, pkey, poiId, navSeq, poiName }: TmapAppInterfaceParams['registPoi']) {
    this.callCustomUrl('registPoi', { type, pkey, poiId, navSeq, poiName })
  }

  showPoiDetailInfo({ poiId, navX, navY, pkey, tailParam }: TmapAppInterfaceParams['showPoiDetailInfo']) {
    this.callCustomUrl('showPoiDetailInfo', {
      poiId,
      navX,
      navY,
      pkey,
      tailParam: tailParam ? JSON.stringify(tailParam) : null,
    })
  }

  copyClipboard({ label, content }: TmapAppInterfaceParams['copyClipboard']) {
    this.callCustomUrl('copyClipboard', { label, content })
  }

  updateAccessKey({ key }: TmapAppInterfaceParams['updateAccessKey']) {
    this.callCustomUrl('updateAccessKey', { key })
  }

  clearCache() {
  }

  notifyChangedUserName({ userName }: TmapAppInterfaceParams['notifyChangedUserName']) {
    this.callCustomUrl('notifyChangedUserName', { userName })
  }

  openOilDiscount(args: TmapAppInterfaceParams['openOilDiscount']) {
    if (gte(this.env.appVersion, '8.2.0')) {
      this.callCustomUrl('openOilDiscount', { pagId: args?.pagId })
    } else {
      this.openBrowser({ url: 'tmap://setting-discount' })
    }
  }

  openNearBy(args: TmapAppInterfaceParams['openNearBy']) {
    this.callCustomUrl('openNearBy', { reqKey: args?.reqKey })
  }

  getAccessKey({ $timeout = 300 }: TmapAppInterfaceParams['getAccessKey'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getAccessKey', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.7.0', $timeout),
    })
  }

  notifyChangedInfo({ type }: TmapAppInterfaceParams['notifyChangedInfo']) {
    this.callCustomUrl('notifyChangedInfo', { type })
  }

  getDisplayInfo({ $timeout = 300 }: TmapAppInterfaceParams['getDisplayInfo'] = {}) {
    return safetyAsyncCall(callJS => {
      this.callCustomUrl('getDisplayInfo', { callJS })
    }, {
      resolver: (resolve, data) => {
        try {
          resolve(JSON.parse(data))
        } catch {
          resolve(null)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('8.2.0', $timeout),
    })
  }

  getDeviceId({ $timeout = 300 }: TmapAppInterfaceParams['getDeviceId'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getDeviceId', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.7.0', $timeout),
    })
  }

  getCarrierName({ $timeout = 300 }: TmapAppInterfaceParams['getCarrierName'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getCarrierName', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.7.0', $timeout),
    })
  }

  getAppSyncApiKey({ $timeout = 300 }: TmapAppInterfaceParams['getAppSyncApiKey'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getAppSyncApiKey', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.9.0', $timeout),
    })
  }

  getEUK({ $timeout = 300 }: TmapAppInterfaceParams['getEUK'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getEUK', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.9.0', $timeout),
    })
  }

  openServiceByName<Data>({
    serviceName,
    jsonData,
    $encodeJsonData = lt(this.env.appVersion, '10.0.0'),
  }: TmapAppInterfaceParams['openServiceByName']) {
    const data = jsonData ? JSON.stringify($encodeJsonData ? applyUrlEncodedProperties(jsonData) : jsonData) : null
    if (lt(this.env.appVersion, '10.0.0')) {
      this.callCustomUrl('openServiceByName', { serviceName, jsonData: data })
      return Promise.resolve(null as Data)
    } else {
      return safetyAsyncCall<Data>(callbackJS => {
        this.callCustomUrl('openServiceByName', {
          serviceName,
          jsonData: data,
          callbackFunctionName: callbackJS,
        })
      }, {
        resolver: (resolve, result) => {
          // closeAndReturnData 참고.
          const decodedCallbackData = decodeURIComponent(result)
          try {
            resolve(JSON.parse(decodedCallbackData))
          } catch {
            resolve(decodedCallbackData as Data)
          }
        },
        callbackId: 'openServiceByNameCallback',
        timeout: null,
      })
    }
  }

  openServiceByUrl({
    url,
    title,
    cacheControl,
    portraitonly,
    useStatusBarArea,
  }: TmapAppInterfaceParams['openServiceByUrl']) {
    return safetyAsyncCall<[string, string]>(callbackJS => {
      this.callCustomUrl('openServiceByUrl', {
        url,
        title: title ?? null,
        callbackJS,
        cacheControl: cacheControl ?? true,
        portraitonly: portraitonly ?? true,
        useStatusBarArea: useStatusBarArea ? 'Y' : 'N',
      })
    }, {
      resolver: (resolve, ...result) => resolve(result as [string, string]),
      callbackId: 'openServiceByUrlCallback',
      timeout: null,
    })
  }

  showNativeTitle({ show, title }: TmapAppInterfaceParams['showNativeTitle']) {
    this.callCustomUrl('showNativeTitle', { show, title })
  }

  playTTS({ guideType, cdn, ttsMessage }: TmapAppInterfaceParams['playTTS']) {
    // tts 플레이타임을 알수없기 때문에 timeout 처리 불가능.
    return safetyAsyncCall<void>(callJS => {
      this.callCustomUrl('playTTS', { guideType, cdn, ttsMessage, callJS })
    }, {
      timeout: null,
    })
  }

  stopTTS() {
    this.callCustomUrl('stopTTS')
  }

  recordEvent({ name, json }: TmapAppInterfaceParams['recordEvent']) {
    this.callCustomUrl(
      'recordEvent',
      { name, json: json ? JSON.stringify(json) : '{}' },
    )
  }

  getUserSetting({ key, $timeout = 300 }: TmapAppInterfaceParams['getUserSetting']) {
    return safetyAsyncCall<string>(callbackJS => {
      this.callCustomUrl('getUserSetting', { key, callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('8.9.0', $timeout),
    })
  }

  shareMessage({ title, body, url }: TmapAppInterfaceParams['shareMessage']) {
    this.callCustomUrl('shareMessage', { title, body, url })
  }

  share({ poiDataJson }: TmapAppInterfaceParams['share']) {
    this.callCustomUrl('share', { poiDataJson: JSON.stringify(poiDataJson) })
  }

  showGNB({ show }: TmapAppInterfaceParams['showGNB']) {
    this.callCustomUrl('showGNB', { show })
  }

  getCurrentPosition({ $timeout = 10000 }: TmapAppInterfaceParams['getCurrentPosition'] = {}) {
    return safetyAsyncCall<Wgs84Coordinate | null>(callJS => {
      this.callCustomUrl('getCurrentPosition', { callJS })
    }, {
      resolver: (resolve, ...coordinates) => {
        if (coordinates[0] == null) {
          resolve(null)
        } else {
          resolve([Number(coordinates[0]), Number(coordinates[1])])
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('8.0.0', $timeout),
    })
  }

  getLoginMethod({ $timeout = 300 }: TmapAppInterfaceParams['getLoginMethod'] = {}) {
    return safetyAsyncCall<LoginMethodType | null>(callbackJS => {
      this.callCustomUrl('getLoginMethod', { callbackJS })
    }, {
      defaultValue: null,
      timeout: this.selectTimeout('8.7.0', $timeout),
    })
  }

  requestTidLogin() {
    return safetyAsyncCall<TIDAuthResult>(callbackJS => {
      this.callCustomUrl('requestTidLogin', { callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve(result as TIDAuthResult),
      callbackId: 'requestTidLoginCallback',
      timeout: null,
    })
  }

  requestConnectCi() {
    return safetyAsyncCall<TIDAuthResult>(callbackJS => {
      this.callCustomUrl('requestConnectCi', { callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve(result as TIDAuthResult),
      callbackId: 'requestConnectCiCallback',
      timeout: null,
    })
  }

  startReportLocation({ url }: TmapAppInterfaceParams['startReportLocation']) {
    this.callCustomUrl('startReportLocation', { url })
  }

  stopReportLocation() {
    this.callCustomUrl('stopReportLocation')
  }

  startPaymentActivity({ url }: TmapAppInterfaceParams['startPaymentActivity']) {
    return safetyAsyncCall<PaymentActivityResult>(callbackJS => {
      this.callCustomUrl('startPaymentActivity', { url, callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve([result[0] || '', result[1] || '']),
      callbackId: 'startPaymentActivityCallback',
      timeout: null,
    })
  }

  startPointActivity({ path }: TmapAppInterfaceParams['startPointActivity']) {
    return safetyAsyncCall<PointActivityResult>(callbackJS => {
      this.callCustomUrl('startPointActivity', { path, callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve([result[0] || '', result[1] || '']),
      callbackId: 'startPointActivityCallback',
      timeout: null,
    })
  }

  handleBackKeyEventFromWeb({ handleFromWeb }: TmapAppInterfaceParams['handleBackKeyEventFromWeb']) {
  }

  selectBottomNavigationItem({ tabName }: TmapAppInterfaceParams['selectBottomNavigationItem']) {
    this.callCustomUrl('selectBottomNavigationItem', {
      tabName: tabName.toLowerCase(),
    })
  }

  setBottomNavigationVisibility({ isShow }: TmapAppInterfaceParams['setBottomNavigationVisibility']) {
    this.callCustomUrl('setBottomNavigationVisibility', { isShow })
  }

  setOrientation({ isPortrait }: TmapAppInterfaceParams['setOrientation']) {
    this.callCustomUrl('setOrientation', { isPortrait })
  }

  isRoadAddressType({ $timeout = 300 }: TmapAppInterfaceParams['isRoadAddressType'] = {}) {
    return safetyAsyncCall<boolean>(callbackJS => {
      this.callCustomUrl('isRoadAddressType', { callbackJS })
    }, {
      resolver: (resolve, value) => {
        resolve(value === 'true')
      },
      defaultValue: false,
      timeout: this.selectTimeout('8.2.0', $timeout),
    })
  }

  clearPushHistory({ pushGroupId }: TmapAppInterfaceParams['clearPushHistory']) {
    this.callCustomUrl('clearPushHistory', { pushGroupId })
  }

  setAsumUserInfo() {
    this.callCustomUrl('setAsumUserInfo')
  }

  getRedDotList({ $timeout = 10000 }: TmapAppInterfaceParams['getRedDotList'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getRedDotList', { callbackJS })
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve([])
        }
      },
      defaultValue: [],
      timeout: this.selectTimeout('9.6.0', $timeout),
    })
  }

  updateRedDotList({ updateData }: TmapAppInterfaceParams['updateRedDotList']) {
    this.callCustomUrl('updateRedDotList', { updateData: JSON.stringify(updateData) })
  }

  sendMomentHappen({ momentCode, importData }: TmapAppInterfaceParams['sendMomentHappen']) {
    this.callCustomUrl('sendMomentHappen', {
      momentCode,
      importData: JSON.stringify(importData),
    })
  }

  getDeviceAdId({ $timeout = 300 }: TmapAppInterfaceParams['getDeviceAdId'] = {}) {
    return safetyAsyncCall<string>(callbackJS => {
      this.callCustomUrl('getDeviceAdId', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('9.1.0', $timeout),
    })
  }

  getDeviceServiceVendorId({ $timeout = 300 }: TmapAppInterfaceParams['getDeviceServiceVendorId'] = {}) {
    return safetyAsyncCall<string>(callbackJS => {
      this.callCustomUrl('getDeviceServiceVendorId', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('9.1.0', $timeout),
    })
  }

  getCurrentMapContext({ $timeout = 300 }: TmapAppInterfaceParams['getCurrentMapContext'] = {}) {
    return safetyAsyncCall<TmapMapContext | null>((callbackJS) => {
      this.callCustomUrl('getCurrentMapContext', { callbackJS })
    }, {
      resolver: (resolve, ...result) => {
        if (result[0] == null) {
          resolve(null)
        } else {
          resolve(result.map(value => Number(value)) as TmapMapContext)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('9.1.0', $timeout),
    })
  }

  setCurrentMapContext({
    x, y, tiltAngle, rotationAngle, zoomLevel,
  }: TmapAppInterfaceParams['setCurrentMapContext']) {
    this.callCustomUrl('setCurrentMapContext', {
      x, y, tiltAngle, rotationAngle, zoomLevel,
    })
  }

  getWebViewMountTime({ $timeout = 300 }: TmapAppInterfaceParams['getWebViewMountTime'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getWebViewMountTime', { callbackJS })
    }, {
      defaultValue: -1,
      resolver: (resolve, result) => {
        resolve(Number(result))
      },
      timeout: this.selectTimeout('9.9.0', $timeout),
    })
  }

  getSessionId({ $timeout = 300 }: TmapAppInterfaceParams['getSessionId'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.callCustomUrl('getSessionId', { callbackJS })
    }, {
      defaultValue: '',
      timeout: this.selectTimeout('9.9.0', $timeout),
    })
  }

  async getLastPosition() {
    return null
  }

  async getPhoneNumber() {
    return null
  }

  requestCILogin() {
    return safetyAsyncCall<CIAuthResult>(callbackJS => {
      this.callCustomUrl('requestCILogin', { callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve(result as CIAuthResult),
      callbackId: 'requestCILoginCallback',
      timeout: null,
    })
  }

  requestCIValidation() {
    return safetyAsyncCall<CIAuthResult>(callbackJS => {
      this.callCustomUrl('requestCIValidation', { callbackJS })
    }, {
      resolver: (resolve, ...result) => resolve(result as CIAuthResult),
      callbackId: 'requestCIValidationCallback',
      timeout: null,
    })
  }

  startQrCodeScanActivity({ title, showBottom = false }: TmapAppInterfaceParams['startQrCodeScanActivity']) {
    return safetyAsyncCall<QRCodeScanResult>(callbackJS => {
      this.callCustomUrl('startQrCodeScanActivity', { callbackJS, title, showBottom })
    }, {
      resolver: (resolve, ...result) => resolve(result as QRCodeScanResult),
      callbackId: 'startQrCodeScanActivityCallback',
      timeout: null,
    })
  }

  async getTmapInfo() {
    //@ts-ignore
    return window.tmapInfo || null
  }

  getRemoteConfig({ key, $timeout = 300 }: TmapAppInterfaceParams['getRemoteConfig']) {
    return safetyAsyncCall<string>(callbackJS => {
      this.callCustomUrl('getRemoteConfig', { callbackJS, key })
    }, {
      timeout: this.selectTimeout('9.13.0', $timeout),
      defaultValue: '',
    })
  }

  openFavoriteRoute() {
    this.callCustomUrl('openFavoriteRoute')
  }

  getFavoriteList({ count = 5, $timeout = 300 }: TmapAppInterfaceParams['getFavoriteList'] = {}) {
    return safetyAsyncCall<{ result: PoiDataJson[] }>(callbackJS => {
      this.callCustomUrl('getFavoriteList', { callbackJS, count })
    }, {
      resolver: (resolve, ...[result]) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve({ result: [] })
        }
      },
      defaultValue: { result: [] },
      timeout: this.selectTimeout('9.0.0', $timeout),
    })
  }

  useStatusBarArea({ use }: TmapAppInterfaceParams['useStatusBarArea']) {
    this.callCustomUrl('useStatusBarArea', { use })
  }

  setStatusBarTextColor({ color }: TmapAppInterfaceParams['setStatusBarTextColor']) {
    this.callCustomUrl('setStatusBarTextColor', { color })
  }

  closeAndReturnData(args?: TmapAppInterfaceParams['closeAndReturnData']) {
    const argData = args?.callbackData
    const callbackData = argData == null ? null : argData
    // 모든 타입 값을 받으므로 일괄 json 스트링 변환한뒤 이스케이프 시퀀스 손실을 막기 위해 url인코딩.
    const encodedCallbackData = encodeURIComponent(JSON.stringify(callbackData))
    this.callCustomUrl('closeAndReturnData', { callbackData: encodedCallbackData })
  }

  pickContact() {
    return safetyAsyncCall<PickContactResult | null>(callbackJS => {
      this.callCustomUrl('pickContact', { callJS: callbackJS })
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve(null)
        }
      },
      callbackId: 'pickContactCallback',
      timeout: null,
    })
  }

  showSoftKeyboard(args: TmapAppInterfaceParams['showSoftKeyboard']) {
  }

  openInAppBrowser({ bottombar, ...args }: TmapAppInterfaceParams['openInAppBrowser']) {
    this.callCustomUrl('openInAppBrowser', {
      jsonString: JSON.stringify({
        ...args,
        bottombar: bottombar == null ? undefined : bottombar ? 'Y' : 'N',
      }),
    })
  }

  toggleFavorite({ poiDataJson }: TmapAppInterfaceParams['toggleFavorite']) {
    return safetyAsyncCall<FavoriteToggleResult>(callbackJS => {
      this.callCustomUrl('toggleFavorite', { callJS: callbackJS, poiDataJson: JSON.stringify(poiDataJson) })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'toggleFavoriteCallback',
      timeout: null,
    })
  }

  getFavoriteState({ poiDataJson }: TmapAppInterfaceParams['getFavoriteState']) {
    return safetyAsyncCall<FavoriteYesNoResult>(callbackJS => {
      this.callCustomUrl('getFavoriteState', { callJS: callbackJS, poiDataJson: JSON.stringify(poiDataJson) })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getFavoriteStateCallback',
      timeout: null,
    })
  }

  togglePublicTransportFavorite({ poiDataJson }: TmapAppInterfaceParams['togglePublicTransportFavorite']) {
    return safetyAsyncCall<TransportFavoriteToggleResult>(callbackJS => {
      this.callCustomUrl('togglePublicTransportFavorite', {
        callJS: callbackJS,
        poiDataJson: JSON.stringify(poiDataJson),
      })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'togglePublicTransportFavoriteCallback',
      defaultValue: { result: false },
      timeout: this.selectTimeout('10.4.0', null, 0),
    })
  }

  getPublicTransportFavoriteState({ poiDataJson }: TmapAppInterfaceParams['getPublicTransportFavoriteState']) {
    return safetyAsyncCall<TransportFavoriteYesNoResult>(callbackJS => {
      this.callCustomUrl('getPublicTransportFavoriteState', {
        callJS: callbackJS,
        poiDataJson: JSON.stringify(poiDataJson),
      })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getPublicTransportFavoriteStateCallback',
      defaultValue: { result: false },
      timeout: this.selectTimeout('10.4.0', null, 0),
    })
  }

  openSubwayRouteDetail({ data }: TmapAppInterfaceParams['openSubwayRouteDetail']) {
    return safetyAsyncCall<SubwayRouteDetailsResult | null>(callbackJS => {
      this.callCustomUrl('openSubwayRouteDetail', { callJS: callbackJS, data: JSON.stringify(data) })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'openSubwayRouteDetailCallback',
      defaultValue: null,
      timeout: this.selectTimeout('10.6.0', null, 0),
    })
  }

  getNearestSubway() {
    return safetyAsyncCall<SubwayRouteData | null>(callbackJS => {
      this.callCustomUrl('getNearestSubway', { callJS: callbackJS })
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getNearestSubwayCallback',
      defaultValue: null,
      timeout: this.selectTimeout('10.6.0', null, 0),
    })
  }

  saveSubwayRoute({ data }: TmapAppInterfaceParams['saveSubwayRoute']) {
    this.callCustomUrl('saveSubwayRoute', { jsonString: JSON.stringify(data) })
  }

  getWebPolicy<Data>({ key }: TmapAppInterfaceParams['getWebPolicy']) {
    return safetyAsyncCall<Data | null>(callbackJS => {
      this.callCustomUrl('getWebPolicy', { key, callbackJS })
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve(result)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('10.6.0', null, 0),
    })
  }

  openAgreementDialog({ groupCode, codeList }: TmapAppInterfaceParams['openAgreementDialog']) {
    this.callCustomUrl('openAgreementDialog', { groupCode, codeList: codeList.join(',') })
  }

  setAgreementExpiredDate({ groupCode, expireDate }: TmapAppInterfaceParams['setAgreementExpiredDate']) {
    this.callCustomUrl('setAgreementExpiredDate', { groupCode, expireDate })
  }

  setAllow({ termsType, items }: TmapAppInterfaceParams['setAllow']) {
    const arg = items
      .map(({ title, code, allow }) =>
        [
          `title=${title}`,
          `code=${code}`,
          `allow=${allow ? 'Y' : 'N'}`,
        ].join('&'),
      ).join('&')
    this.callCustomUrl('setAllow', { termsType, arg })
  }

}
